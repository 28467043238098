<template>
  <div>
    <div class="content">

      <md-button @click="newRockModal = true" class="md-fab md-fab-bottom-right md-fixed md-primary">
        <md-icon>add</md-icon>
      </md-button>

      <md-field v-if="dataLoaded && !noRocks && !coldStart">
        <label>Search</label>
        <md-input @keyup.enter.native="searchRocks" v-model="search"></md-input>
      </md-field>

      <template v-if="searching">
        <md-progress-bar class="searching md-accent" md-mode="indeterminate"></md-progress-bar>
      </template>

      <template v-else>
        <md-card v-for="rock in rocks" :key="rock.id">
          <div class="pointer" @click="goToRock(rock.id)">
            <md-card-header>
              <md-card-header-text>
                <div class="md-title">{{rock.name}}</div>
                <div class="md-subhead">{{rock.notes}}</div>
                <!-- <div class="md-subhead">Picture Count: {{rock.picture_count}}</div> -->
              </md-card-header-text>

              <md-card-media v-if="rock.thumbnailSrc">
                <img class="thumbnail" :src="rock.thumbnailSrc">
              </md-card-media>
              <md-card-media v-else-if="rock.imageSrc">
                <img :src="rock.imageSrc">
              </md-card-media>

            </md-card-header>
          </div>

          <md-card-actions>
            <md-button @click="editRockModal = true; activeRock = rock">
              <md-icon>edit</md-icon>
            </md-button>
            <md-button @click="newRockPictureModal = true; activeRock = rock">
              <md-icon>image</md-icon>
            </md-button>
            <md-button @click="deleteRockModal = true; activeRock = rock">
              <md-icon>delete</md-icon>
            </md-button>
          </md-card-actions>

        </md-card>
      </template>

      <md-empty-state
        v-if="dataLoaded && noRocks"
        md-icon="fiber_new"
        md-label="Log your first find"
        md-description="Capture information, pictures, and more about your rocks!">
        <md-button @click="newRockModal = true" class="md-primary md-raised">Create one now</md-button>
      </md-empty-state>

      <md-empty-state
        v-if="dataLoaded && emptySearch"
        md-icon="sentiment_dissatisfied"
        md-label="No rocks match your search"
        md-description="Logging your finds will allow you to track, search, and organize your finds!">
      </md-empty-state>

      <md-empty-state
        v-if="dataLoaded && coldStart"
        md-icon="snooze"
        md-label="Booting up..."
        md-description="The hamsters are still stretching, shouldn't take more than a minute.">
        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
      </md-empty-state>
    </div>

    <md-dialog :md-active.sync="newRockModal">
      <md-dialog-title>New Rock!</md-dialog-title>
      <md-dialog-content>
        <form class="md-layout form-margin">
          <md-field>
            <label>Name</label>
            <md-input v-model="newRock.name"></md-input>
          </md-field>
          <md-field>
            <label>Notes</label>
            <md-textarea v-model="newRock.notes"/>
          </md-field>
          <md-field v-for="(value, key) in rockAttributes" :key="key">
            <label>{{key}}</label>
            <md-input v-model="rockAttributes[key]"></md-input>
            <md-button @click="$delete(rockAttributes, key)">
              <md-icon>close</md-icon>
            </md-button>
          </md-field>
          <!-- <md-field v-for="attribute in rockAttributes" :key="attribute">
            <label>{{attribute.key}}</label>
            <md-input v-model="rock.attributes[attribute]"></md-input>
          </md-field> -->
          <md-field>
            <label>New Custom Attribute</label>
            <md-input @keyup.enter.native="$set(rockAttributes,newAttribute,''); newAttribute = null" v-model="newAttribute"></md-input>
          </md-field>
          <md-button @click="newRockModal = false">Nevermind</md-button>
          <md-button class="md-primary" @click="postRock">
            <template v-if="processing">
              <md-progress-spinner v-if="processing" :md-stroke="2" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
            </template>
            <template v-else>
              Create!
            </template>
          </md-button>
        </form>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="newRockPictureModal">
      <md-dialog-title>New Rock Pictures!</md-dialog-title>
      <form class="md-layout form-margin">
        <md-field>
          <label>Files</label>
          <md-file @md-change="setRockPictures" multiple />
        </md-field>
        <md-button @click="newRockPictureModal = false">Nevermind</md-button>
        <md-button class="md-primary" @click="postRockPictures">            
          <template v-if="processing">
            <md-progress-spinner v-if="processing" :md-stroke="2" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
          </template>
          <template v-else>
            Upload!
          </template>
        </md-button>
      </form>
    </md-dialog>


    <md-dialog :md-active.sync="editRockModal">
      <md-dialog-title>Edit your Rock's information</md-dialog-title>
      <md-dialog-content>
        <form class="md-layout form-margin">
          <md-field>
            <label>Name</label>
            <md-input v-model="activeRock.name"></md-input>
          </md-field>
          <md-field>
            <label>Notes</label>
            <md-textarea v-model="activeRock.notes"/>
          </md-field>
            <md-field v-for="(value, key) in rockAttributes" :key="key">
              <label>{{key}}</label>
              <md-input v-model="rockAttributes[key]"></md-input>
              <md-button @click="$delete(rockAttributes, key)">
                <md-icon>close</md-icon>
              </md-button>
            </md-field>
            <md-field>
              <label>New Custom Attribute</label>
              <md-input @keyup.enter.native="$set(rockAttributes,newAttribute,''); newAttribute = null" v-model="newAttribute"></md-input>
            </md-field>
          <md-button @click="editRockModal = false">Nevermind</md-button>
          <md-button class="md-primary" @click="putRock">
          <template v-if="processing">
            <md-progress-spinner v-if="processing" :md-stroke="2" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
          </template>
          <template v-else>
            Update!
          </template>
        </md-button>
        </form>
      </md-dialog-content>
    </md-dialog>


    <md-dialog :md-active.sync="deleteRockModal">
      <md-dialog-title>Are you sure you wish to delete {{activeRock.name}}?</md-dialog-title>
      <form class="md-layout form-margin">
        <md-button @click="deleteRockModal = false">Nevermind</md-button>
        <md-button class="md-primary" @click="deleteRock">            
          <template v-if="processing">
            <md-progress-spinner v-if="processing" :md-stroke="2" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
          </template>
          <template v-else>
            Delete
          </template>
        </md-button>
      </form>
    </md-dialog>



  </div>

</template>

<script>
import imageCompression from 'browser-image-compression';
import { Auth, Storage } from 'aws-amplify';
export default {
  name: 'Rocks',
  props: {
    msg: String
  },
  data: function(){ 

    return {
      apiUrl: "https://od2bx2nxoi.execute-api.us-east-1.amazonaws.com/prod",
      showSidepanel: false,
      showNavigation: false,
      newRockModal: false,
      newRockPictureModal: false,
      deleteRockModal: false,
      rockPictures: [],
      editRockModal: false,
      newAttribute: null,
      rockAttributes: {},
      processing: false,
      searching: false,
      coldStart: false,
      search: null,
      noRocks: false,
      emptySearch: false,
      dataLoaded: false,
      newRock: {
        name: null,
        notes: null,
        attributes: {},
      },
      activeRock: {
        name: null,
        notes: null,
        attributes: {},
      },
      auth: Auth,
      user: {},
      username: null,
      session: {},
      authHeader: null,
      rocks: undefined,
      name: null,
      notes: null,
      imageSource: null,
      fields: [
        { sortable: true, key: "name", label: "Name"},
        { sortable: true, key: "notes", label: "Notes"},
        { sortable: false, key: "tags", label: "Tags"},
      ],
    }
  },
  async created(){
    this.user = await Auth.currentAuthenticatedUser();
    this.username = this.user.username;
    this.session = await Auth.currentSession();
    this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
    this.getData();
  }, 
  watch: {
    async editRockModal(e){
      if (e){
        const attributes = await this.getRockAttributes(this.activeRock.id);
        attributes.map(a=>{
          this.$set(this.rockAttributes, a.key, a.value);
        })
      }
    }
  },
  methods: {
    async getData(){
      await this.getRocks();
      this.dataLoaded = true;
    },
    async getRocks(){
      this.searching = true;
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      try {
        const response = await fetch(`${this.apiUrl}/rocks`, { headers });
        const data = await response.json();
        this.searching = false;
        this.coldStart = false;
        for (let i=0; i<data.length; i++){
          const rock = data[i];
          if (rock.image_id){
            data[i].thumbnailSrc = await this.getThumbnailSrc(rock.image_id, rock.id);
          }
        }
        this.rocks = data;
        if (this.rocks.length === 0){
          this.noRocks = true;
        }
        else {
          this.noRocks = false;
        }
      }
      catch(e){
        this.coldStart = true;
        setTimeout(this.getRocks(), 15000);
      }
    },
    async postRock(){
      this.processing = true;
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks`, { 
        method: "POST",
        headers,
        body: JSON.stringify({"name": this.newRock.name, "notes": this.newRock.notes}),
      });
      const data = await response.json();
      await this.postRockAttributes(data.rockId);
      const date = new Date().toISOString();
      this.rocks.unshift({
        id: data.rockId,
        imageSrc: null,
        thumbnailSrc: null,
        image_id: null,
        name: this.newRock.name,
        notes: this.newRock.notes,
        picture_count: 0,
        date_created: date,
        date_modified: date,
      })
      this.processing = false;
      this.newRockModal = false;
      this.noRocks = false;
    },
    async putRock(){
      this.processing = true;
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const rockId = this.activeRock.id;
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}`, { 
        method: "PUT",
        headers,
        body: JSON.stringify({"name": this.activeRock.name, "notes": this.activeRock.notes}),
      });
      const data = await response.json();
      await this.postRockAttributes(rockId);
      this.processing = false;
      this.editRockModal = false;
      return data;
    },
    async postRockAttributes(rockId){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}/attributes`, { 
        method: "POST",
        headers,
        body: JSON.stringify(this.rockAttributes),
      });
      const data = await response.json();
      return data;  
    },
    async postRockPicture(rockId, name){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}/pictures`, { 
        method: "POST",
        headers,
        body: JSON.stringify({"name": name}),
      });
      const data = await response.json();
      return data;
    },
    async postRockPictures(){
      this.processing = true;
      const rockId = this.activeRock.id;
      let imageId;
      for (let i=0; i<this.rockPictures.length; i++){
        let file = this.rockPictures[i];
        if (file.size > 4990000){
          file = await imageCompression(file, {maxSizeMB: 4.99 });
        }
        const response = await this.postRockPicture(rockId, file.name);
        await Storage.put(`${rockId}/images/${response.imageId}`, file, {
          level: "private",
          contentType: file.type,
        });
        if (file.size > 25000){
          file = await imageCompression(file, { maxSizeMB: .025 });
        }
        await Storage.put(`${rockId}/thumbnails/${response.imageId}`, file, {
          level: "private",
          contentType: file.type,
        });
        imageId = response.imageId;
      }
      const rockIdx = this.rocks.map(function(e) { return e.id; }).indexOf(rockId);
      const newRock = this.rocks[rockIdx];
      // Eventually figure out a way to do this more nicely...
      // And use the image they uploaded instead of going back to the server.
      newRock["thumbnailSrc"] = await this.getThumbnailSrc(imageId, rockId)
      this.$set(this.rocks, rockIdx, newRock);
      this.processing = false;
      this.newRockPictureModal = false;
    },
    async deleteRock(){
      this.processing = true;
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const rockId = this.activeRock.id;
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}`, { 
        method: "DELETE",
        headers,
      });
      const data = await response.json();
      const rockIdx = this.rocks.map(function(e) { return e.id; }).indexOf(rockId);
      this.$delete(this.rocks, rockIdx);
      this.processing = false;
      this.deleteRockModal = false;
      return data;
    },
    setRockPictures(e){
      this.rockPictures = e;
    },
    async getThumbnailSrc(fileId, rockId){
      if (!rockId){
        rockId = this.activeRock.id;
      }
      const src = await Storage.get(`${rockId}/thumbnails/${fileId}`, { level: "private" });
      return src;
    },    
    async getImageSrc(fileId, rockId){
      if (!rockId){
        rockId = this.activeRock.id;
      }
      const src = await Storage.get(`${rockId}/images/${fileId}`, { level: "private" });
      return src;
    },    
    async getRockAttributes(rockId){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      try {
        const response = await fetch(`${this.apiUrl}/rocks/${rockId}/attributes`, { headers });
        const data = await response.json();
        return data;
      }
      catch(e){
        console.log(e);
      }
    },
    async searchRocks(){
      this.emptySearch = false;
      this.searching = true; 
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks/search`, { 
        method: "POST",
        headers,
        body: JSON.stringify({"term": this.search}),
      });
      const data = await response.json();
      for (let i=0; i<data.length; i++){
        const rock = data[i];
        if (rock.image_id){
          data[i].thumbnailSrc = await this.getThumbnailSrc(rock.image_id, rock.id);
        }
      }
      this.searching = false;
      this.rocks = data;
      if (this.rocks.length === 0){
        this.emptySearch = true;
      }
      else {
        this.emptySearch = false;
      }
    },
    goToRock(rockId){
      this.$router.push({ name: 'Rock', params: {rockId: rockId}});
    }
    // https://docs.amplify.aws/lib/storage/download/q/platform/js#file-download-option
    // async downloadImage(file){
    //   const rockId = this.activeRock.id;
    //   return await Storage.get(`${rockId}/images/${file.id}`, { 
    //     level: "private",
    //     download: true,
    //     contentType: file.contentType,
    //   });
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lange="scss" scoped>
  .md-card {
    width: 320px;
    margin: 12px;
    display: inline-block;
    vertical-align: top;
  }
  .form-margin {
    margin: 24px;
  }
  .content {
    margin: 48px;
  }
  .pointer {
    cursor: pointer;
  }
  .searching {
    margin: 48px;
  }
  .thumbnail {
    max-height: 96px;
    max-width: 96px;
    width: unset;
    border-radius: 3%;
  }
  .md-card {
    border-radius: 3%;
  }
  .md-card-header .md-card-media {
      height: unset;
      width: unset;
  }

</style>
