import Vue from 'vue'
import VueRouter from 'vue-router'
import Rock from '../views/Rock.vue'
import Rocks from '../views/Rocks.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        // alias: '/',
        name: 'Rocks',
        component: Rocks
    },
    {
        path: '/rocks/:rockId',
        // alias: 'rock',
        name: 'Rock',
        component: Rock,
    }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;