<template>
  <div class="page-container md-layout-column" id="app">

    <amplify-authenticator v-if="authState!='signedin'">
      <amplify-sign-in header-text="Log in to manage your rocks!" slot="sign-in"></amplify-sign-in>
    </amplify-authenticator>

    <template v-if="authState==='signedin'">
      <md-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="showNavigation = true">
          <md-icon>menu</md-icon>
        </md-button>
        <span @click="$router.push('/')" class="pointer md-title">My Rocks</span>

        <!-- <div class="md-toolbar-section-end">
          <md-button @click="showSidepanel = true">Search</md-button>
        </div> -->
      </md-toolbar>

      <md-drawer :md-active.sync="showNavigation" md-swipeable>
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Rocks</span>
        </md-toolbar>

        <md-list>
          <md-list-item @click="$router.push('/')">
            <md-icon>landscape</md-icon>
            <span class="md-list-item-text">Rocks</span>
          </md-list-item>

          <md-list-item @click="signOut" >
            <md-icon>no_meeting_room</md-icon>
            <span class="md-list-item-text">
              Sign Out
            </span>
          </md-list-item>

        </md-list>
      </md-drawer>

      <!-- <md-drawer class="md-right" :md-active.sync="showSidepanel">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Favorites</span>
        </md-toolbar>

        <md-list>
          <md-list-item>
            <span class="md-list-item-text">Abbey Christansen</span>

            <md-button class="md-icon-button md-list-action">
              <md-icon class="md-primary">chat_bubble</md-icon>
            </md-button>
          </md-list-item>

          <md-list-item>
            <span class="md-list-item-text">Alex Nelson</span>

            <md-button class="md-icon-button md-list-action">
              <md-icon class="md-primary">chat_bubble</md-icon>
            </md-button>
          </md-list-item>

          <md-list-item>
            <span class="md-list-item-text">Mary Johnson</span>

            <md-button class="md-icon-button md-list-action">
              <md-icon>chat_bubble</md-icon>
            </md-button>
          </md-list-item>
        </md-list>
      </md-drawer> -->

      <router-view/>
    </template>


  </div>
</template><script>
// import Rocks from "./components/Rocks.vue";
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
export default {
  name: "App",
  components: {
    // Rocks
  },  
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    })
  },
  methods: {
    async signOut(){
      await Auth.signOut();
    }
  }, 
  data() {
    return {
      user: undefined,
      authState: undefined,
      showSidepanel: false,
      showNavigation: false,
    }
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  }
};
</script>
<style scoped>
.page-container {
  min-height: 300px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(#000, .12);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.pointer {
  cursor: pointer
}
</style>