// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3e2c27b3-4c05-46d8-92e0-c07a47a83fa6",
    "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-east-1_MzsYYchlj",
    "aws_user_pools_id": "us-east-1_vBKIQkGyF",
    //"aws_user_pools_web_client_id": "6tni6tfr21udq8ggrhfrdf03ie",
    "aws_user_pools_web_client_id": "124dom6msnugo2rrb3uhc8mq5f",
    "aws_user_files_s3_bucket": "appstack-bucketd7feb781-z4qko5yp2zyx",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "oauth": {},
};


export default awsmobile;
