<template>
  <div>
    <div class="content">
      <!-- <md-button @click="newRockModal = true" class="md-fab md-fab-bottom-right md-fixed md-primary">
        <md-icon>add</md-icon>
      </md-button> -->
      <h1>{{rock.name}}</h1>
      <p>{{rock.notes}}</p>

      <template v-if="rockAttributes && rockAttributes.length > 0">
        <md-divider class="divider"></md-divider>

        <md-table class="center">
          <md-table-row>
            <md-table-head>Attribute</md-table-head>
            <md-table-head>Value</md-table-head>
          </md-table-row>

          <md-table-row v-for="attribute in rockAttributes" :key="attribute.id">
            <md-table-cell>{{attribute.key}}</md-table-cell>
            <md-table-cell>{{attribute.value}}</md-table-cell>
          </md-table-row>
        </md-table>
      </template>

      <template v-if="rockPictures && rockPictures.length > 0">
        <md-divider class="divider"></md-divider>

        <md-card v-for="picture in rockPictures" :key="picture.id">
            <md-card-media v-if="picture.imageSrc">
              <img class="thumbnail" :src="picture.imageSrc">
            </md-card-media>
            <md-card-media v-else-if="picture.thumbnailSrc">
              <img class="thumbnail" @click="viewFullImage(picture)" :src="picture.thumbnailSrc">
            </md-card-media>
        </md-card>
      </template>

    </div>

    <md-dialog :md-active.sync="viewFullImageModal">
      <md-dialog-content>
        <img v-if="activeImage.imageSrc" :src="activeImage.imageSrc">
        <md-button @click="viewFullImageModal = false">Close</md-button>
      </md-dialog-content>
    </md-dialog>

  </div>

</template>

<script>
import { Auth, Storage } from 'aws-amplify';
export default {
  name: 'Rocks',
  props: {
    msg: String
  },
  data: function(){ 

    return {
      apiUrl: "https://od2bx2nxoi.execute-api.us-east-1.amazonaws.com/prod",
      rockId: null,
      showSidepanel: false,
      showNavigation: false,
      viewFullImageModal: false,
      activeImage: {
        id: null,
        imageSrc: null,
        thumbnailSrc: null,
      },
      rock: {
        name: null,
        notes: null,
        attributes: null,
      },
      rockPictures: [],
      rockAttributes: [],
      auth: Auth,
      user: {},
      username: null,
      session: {},
      authHeader: null,
      fields: [
        { sortable: true, key: "attributeName", label: "Attribute"},
        { sortable: true, key: "attributeValue", label: "Value"}
      ],
    }
  },
  async created(){
    this.user = await Auth.currentAuthenticatedUser();
    this.username = this.user.username;
    this.session = await Auth.currentSession();
    this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
    this.rockId = this.$route.params.rockId;
    this.getRock();
    this.getRockAttributes(this.rockId);
    this.getRockPictures(this.rockId);
    
  }, 
  methods: {
    async getRock(){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      try {
        const response = await fetch(`${this.apiUrl}/rocks/${this.rockId}`, { headers });
        const data = await response.json();
        this.rock = data[0];
      }
      catch(e){
        console.log(e);
      }
    },
    async getRockAttributes(rockId){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      try {
        const response = await fetch(`${this.apiUrl}/rocks/${rockId}/attributes`, { headers });
        const data = await response.json();
        this.rockAttributes = data;
      }
      catch(e){
        console.log(e);
      }
    },
    async getRockPictures(rockId){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      try {
        const response = await fetch(`${this.apiUrl}/rocks/${rockId}/pictures`, { headers });
        const data = await response.json();
        for (let i=0; i<data.length; i++){
          const picture = data[i];
          this.rockPictures.push({
            id: picture.id,
            thumbnailSrc: await this.getThumbnailSrc(picture.id, rockId),
            name: picture.name
          })
        }
      }
      catch (e){
        console.log(e);
      }
    },
    async postRock(){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks`, { 
        method: "POST",
        headers,
        body: JSON.stringify({"name": this.newRock.name, "notes": this.newRock.notes}),
      });
      const data = await response.json();
      console.log(data);
      this.rocks
      this.newRockModal = false;
    },
    async putRock(){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const rockId = this.activeRock.id;
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}`, { 
        method: "PUT",
        headers,
        body: JSON.stringify({"name": this.activeRock.name, "notes": this.activeRock.notes}),
      });
      const data = await response.json();
      console.log(data);
      this.editRockModal = false;
    },
    async postRockPicture(rockId, name){
      const headers = { "Content-Type": "application/json", "Authorization": this.authHeader};
      const response = await fetch(`${this.apiUrl}/rocks/${rockId}/pictures`, { 
        method: "POST",
        headers,
        body: JSON.stringify({"name": name}),
      });
      const data = await response.json();
      console.log(data);
      return data;
    },
    async postRockPictures(){
      for (let i=0; i<this.rockPictures.length; i++){
        const file = this.rockPictures[i];
        const rockId = this.activeRock.id; // TODO REPLACE THIS WITH THE CORRECT ROCKID
        const response = await this.postRockPicture(rockId, file.name);
        console.log(file);
        await Storage.put(`${rockId}/images/${response.imageId}`, file, {
          // metadata: {
          //   imageId: response.imageId,
          //   rockId,
          //   fileName: file.name,
          // },
          level: "private",
          contentType: file.type,
        });
      }
      this.newRockPictureModal = false;
    },
    async getThumbnailSrc(fileId, rockId){
      if (!rockId){
        rockId = this.activeRock.id;
      }
      const src = await Storage.get(`${rockId}/thumbnails/${fileId}`, { level: "private" });
      return src;
    },
    async getImageSrc(fileId, rockId){
      if (!rockId){
        rockId = this.activeRock.id;
      }
      const src = await Storage.get(`${rockId}/images/${fileId}`, { level: "private" });
      return src;
    },
    async viewFullImage(image){
      console.log(image);
      this.activeImage.imageSrc = await this.getImageSrc(image.id, this.rockId);
      this.viewFullImageModal = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lange="scss" scoped>
  .md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
  .form-margin {
    margin: 24px;
  }
  /* .content {
    margin: 48px;
  } */
  .center {
    width: min-content;
    margin: auto;
  }
  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .md-card-media {
    align-content: space-around;
    display: inline-grid;
    /* max-height:240px; */
    /* max-width:240px; */
    margin:12px;
    height:240px;
  }
  .thumbnail{
    border-radius:3%;
    max-height:240px;
    cursor: pointer;
  }
  .md-card {
    border-radius: 3%;
  }
  .md-card-media img {
      width: unset;
  }
</style>
